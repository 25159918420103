import React from 'react';
import Select from 'react-select';

// このhookを用いなくても,各ページに直アクセスしてスタイルが乱れなければ削除可能
// 参考: https://github.com/Taimee/timee-client-web/pull/217
export const useFixEmotionBug = () => {
  const render = () => (
    <div style={{ display: 'none' }}>
      <Select options={[]} inputId={'use-fix-emotion-bug'} />
    </div>
  );

  return {
    render: render,
  };
};

import React, { useState } from 'react';
import Head from 'next/head';
import { useEffect } from 'react';

export const useViewportTrimmer = (minWidth: number) => {
  const [contentConfig, setContentConfig] = useState('width=device-width, user-scalable=0');

  const getCurrentWidth = (): number => {
    return window.screen.width || window.outerWidth;
  };

  const updateMetaViewport = (): void => {
    const currentWidth = getCurrentWidth();

    setContentConfig(
      `${currentWidth < minWidth ? `width=${minWidth}` : 'width=device-width'}, user-scalable=0`
    );
  };

  useEffect(() => {
    //イベントハンドラ登録
    window.addEventListener('resize', updateMetaViewport, false);
    window.addEventListener('orientationchange', updateMetaViewport, false);
  }, []);

  return {
    render: () => (
      <Head>
        <meta name="viewport" content={contentConfig} />
      </Head>
    ),
  };
};

import React from 'react';
import { LayoutComponent } from '~/types/layout';
import * as styles from './index.styles';

export const TESTID = {
  CONTAINER: 'CONTAINER',
} as const;

export const SingleActionLayout: LayoutComponent = (props) => (
  <div css={styles.bg}>
    <div css={styles.container} data-testid={TESTID.CONTAINER}>
      {props.children}
    </div>
  </div>
);

import axios from 'axios';

type ErrorType =
  | 'NETWORK_ERROR'
  | 'SERVER_ERROR'
  | 'NOT_FOUND_ERROR'
  | 'VALIDATION_ERROR'
  | 'NOT_ERROR'
  | 'UNEXPECTED_ERROR';

// axios.isAxiosErrorとstatusを見てエラーの種類を分類する
export const classifyError = (error: unknown): ErrorType => {
  if (axios.isAxiosError(error)) {
    if (!error.response) return 'NETWORK_ERROR';
    if (error.response?.statusText.charAt(0) === '2') return 'NOT_ERROR';
    if (error.response?.statusText.charAt(0) === '5') return 'SERVER_ERROR';
    if (error.response?.status === 404) return 'NOT_FOUND_ERROR';
    if (error.response?.status === 422) return 'VALIDATION_ERROR';
  }
  return 'UNEXPECTED_ERROR';
};

import { useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

export const useGTM = () => {
  // NOTE: GTM上でドメインを見てタグの設定をしているのでベタ書きしている
  const tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-M6R87QR',
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
};

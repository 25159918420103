export const colors = {
  // figma上で定義されている色
  main: '#ffd700',
  mainPale: '#fff9d8',
  accent: '#007aff',
  accentPale: '#bfdeff',
  accentVeryPale: '#ebf5Ff',
  alert: '#ff5757',
  alertPale: '#fff3F3',
  success: '#33d043',
  successPale: '#ECFFEE',
  black: '#202020',
  gray1: '#323232',
  gray2: '#484848',
  gray3: '#666',
  gray4: '#999',
  gray5: '#eaeaea',
  gray6: '#f4f4f4',
  gray7: '#fafafa',
  white: '#fff',
  tableBg: '#F5F7F8',
  background: '#fbfbfb',
  outline: '#0067c9',

  // 以下はフロントで使用する色
  red: '#ff5757',
  blue: '#007aff',
  green: '#33d043',
  yellowPale: '#fff9d8',
  yellowDark: '#d1b000',
  warning: '#ffd700',
  warningPale: '#fff9d8',
  progressBar: '#007aff',
  emptyLayoutBg: '#f4f4f4',
} as const;

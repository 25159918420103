import React from 'react';
import { alignCss } from '~/styles/alignCss';
import CharactorMaintenance from '~/assets/logo/charactor-maintenance.svg';
import { publicEnv } from '~/constants/publicEnv';

export type Props = {};

export const MaintenancePage: React.FC<Props> = () => (
  <div css={alignCss({ align: 'center' })}>
    <img src={CharactorMaintenance} alt="" width={'120px'} />

    <div css={alignCss({ align: 'center', mt: 3 })}>現在、メンテナンス中です。</div>
    <div css={alignCss({ align: 'center', mt: 3 })}>
      <div css={alignCss({ align: 'center', mb: 1 })}>ご不便をおかけしますが、</div>
      <div>今しばらくお待ちください。</div>
    </div>
    {publicEnv.maintenanceMessage && (
      <div css={alignCss({ align: 'center', mt: 3 })}>{publicEnv.maintenanceMessage}</div>
    )}
  </div>
);

import toast from 'react-hot-toast';
import { Message } from '~/types/message';

let messagesCache: (Message & { createdAt: number })[] = [];

const sameMessage = (a: Message, b: Message): boolean =>
  a.type === b.type && a.body.join('/') === b.body.join('/');

const showToast = (errorMessage: Message): void => {
  if (errorMessage.type === 'success') errorMessage.body.forEach((v) => toast.success(v));
  else if (errorMessage.type === 'error') errorMessage.body.forEach((v) => toast.error(v));
  else errorMessage.body.forEach((v) => toast(v));
};

// NOTE: 同じメッセージのトーストを短い時間間隔で表示しないようにするための機構
export const showToastWithCache = (
  newMessage: Message,
  showToastFn = showToast,
  getTimeFn = () => new Date().getTime(),
  getMessageCache = () => messagesCache,
  setMessageCache = (newMessages: (Message & { createdAt: number })[]) =>
    (messagesCache = newMessages)
): void => {
  // messagesCacheを古いメッセージをクリア
  const currentTime = getTimeFn();
  const newMessages = getMessageCache().filter((m) => m.createdAt + 1000 > currentTime);

  // messageCacheと同じメッセージがなければ表示してcacheに追加して表示
  if (!newMessages.some((m) => sameMessage(m, newMessage))) {
    showToastFn(newMessage);
    newMessages.push({ ...newMessage, createdAt: getTimeFn() });
  }

  setMessageCache(newMessages);
};

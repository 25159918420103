import TagManager from 'react-gtm-module';

const setUserType = (asPath: string) => {
  const type: 'SUPPORTED' | 'UNSUPPORTED' = asPath.includes('?hs_token')
    ? 'SUPPORTED'
    : 'UNSUPPORTED';

  // TODO: `/`が削除されたら(`/support`, `/self`のみになったら以下に条件を変える)
  // const type: 'SUPPORTED' | 'UNSUPPORTED' | null = (() => {
  //   if (asPath.includes('/support')) return 'SUPPORTED';
  //   if (asPath.includes('/self')) return 'UNSUPPORTED';
  //   return null;
  // })();

  // if (type === null) return;

  TagManager.dataLayer({
    dataLayer: {
      userType: type,
    },
  });
};

export const fireGAEvent = {
  initGA: () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'INIT_GA',
      },
    });
  },
  customEvents: {
    viewTACAndInputEmailSection: (asPath: string) => {
      setUserType(asPath);
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_TAC_AND_INPUT_EMAIL_SECTION',
        },
      });
    },
    viewConfirmEmailSection: (asPath: string) => {
      setUserType(asPath);
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_CONFIRM_EMAIL_SECTION',
        },
      });
    },
    clickWorkerAppLink: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: `CLICK_WORKER_APP_LINK`,
        },
      });
    },
    completeClientRegistration: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'COMPLETE_CLIENT_REGISTRATION',
        },
      });
    },
  },
};

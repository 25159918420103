import { publicEnv } from '~/constants/publicEnv';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useDatadogRUM = () => {
  const router = useRouter();

  useEffect(() => {
    if (publicEnv.environment !== 'production' || router.asPath.startsWith('/preview')) return;

    datadogRum.init({
      applicationId: publicEnv.dataDogRUM.applicationId,
      clientToken: publicEnv.dataDogRUM.clientToken,
      site: 'datadoghq.com',
      service: publicEnv.dataDogRUM.service,
      env: publicEnv.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });
    datadogRum.startSessionReplayRecording();
  });
};

const baseSize = {
  margin: 8,
  borderRadius: 8,
} as const;

const pagePadding = {
  desktop: baseSize.margin * 4,
  mobile: baseSize.margin * 2,
};

export const layout = {
  baseSize,
  pagePadding,
};

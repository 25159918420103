import { css, SerializedStyles } from '@emotion/react';

export const linkStyle = (color?: string): SerializedStyles => css`
  ${color ? `color: ${color};` : ''}

  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-color: currentcolor;
  cursor: pointer;
`;

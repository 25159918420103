import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';

export const bg = css`
  height: 100%;
  background-color: ${colors.emptyLayoutBg};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${layout.baseSize.margin * 2}px;
`;

export const container = css`
  box-shadow: 0 0 10px rgba(0 0 0 / 10%);
  background-color: ${colors.white};
  box-sizing: border-box;
  max-width: 400px;
  width: 100%;
  padding: ${layout.baseSize.margin * 4}px;
  border-radius: ${layout.baseSize.borderRadius * 2}px;
`;

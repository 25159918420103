import { fixedPhrase } from '~/constants/fixedPhrase';
import { Message } from '~/types/message';
import { classifyError } from './classifyError';
import { errorMessagesFromErrorResponse } from './errorMessagesFromErrorResponse';

// APIクライアントの例外のエラーをメッセージの型に変換する
// エラーじゃなければnullを返す
export const errorToMessage = (error: unknown): Message | null => {
  const errorMessages = errorMessagesFromErrorResponse(error);

  const defaultErrorMessage: string | null = (() => {
    switch (classifyError(error)) {
      case 'NETWORK_ERROR':
        return fixedPhrase.networkError;
      case 'SERVER_ERROR':
        return fixedPhrase.serverError;
      case 'NOT_FOUND_ERROR':
        return fixedPhrase.notFoundError;
      case 'VALIDATION_ERROR':
        return fixedPhrase.validationError;
      case 'UNEXPECTED_ERROR':
        return fixedPhrase.unknownError;
      case 'NOT_ERROR':
        return null;
      default:
        return fixedPhrase.unknownError;
    }
  })();

  return defaultErrorMessage
    ? {
        type: 'error',
        body: errorMessages ?? [defaultErrorMessage],
      }
    : null;
};

import { useEffect } from 'react';
import { publicEnv } from '~/constants/publicEnv';

export const useSalesforceTracking = () => {
  useEffect(() => {
    window.piAId = publicEnv.salesforce.pid;
    window.piCId = '';
    window.piHostname = publicEnv.salesforce.trackingHost;

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      window.piHostname +
      '/pd.js';
    const c = document.getElementsByTagName('script')[0];
    c.parentNode?.insertBefore(s, c);
  }, []);
};

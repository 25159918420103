import React from 'react';
import Script from 'next/script';
import { publicEnv } from '~/constants/publicEnv';

export const useHubspotTracking = () => {
  const render = () => <Script id="hs-script-loader" src={publicEnv.hubspotTrackingScript} />;

  return {
    render: render,
  };
};
